import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Spin, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { API_BASE_URL } from '../../utils/config';

const LoadingIcon = (
  <LoadingOutlined
    style={{
      fontSize: 48,
    }}
    spin
  />
);
const Auth = () => {
  const { search } = useLocation();

  useEffect(() => {
    if (search) {
      axios
        .get(`${API_BASE_URL}/google/auth${search}`)
        .then((response) => {
          if (response.data.token) {
            if (window.opener) {
              const redirectPath = sessionStorage.getItem('redirectPath');
              window.opener.postMessage(
                { ...response.data, redirectPath },
                window.location.origin
              );
              window.close();
            }
          } else if (response.data.detail === '400: Failed to fetch access token') {
            notification.warning({
              message: response.data.detail,
              placement: 'top',
              key: response.data.detail,
              duration: null,
            });
          }
        })
        .catch((error) => {
          notification.warning({
            message: error.toString(),
            placement: 'top',
            key: error.toString(),
            duration: null,
          });
        });
    }
  }, [search]);

  return (
    <div>
      <Row
        justify="center"
        align="middle"
        gutter={[
          { xs: 16, sm: 16, md: 16, lg: 16 },
          { xs: 16, sm: 16, md: 16, lg: 16 },
        ]}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100vh',
            }}
          >
            <div>
              <Spin indicator={LoadingIcon} />
              <span style={{ display: 'block', marginTop: 16, color: 'rgba(0,0,0,0.65)' }}>載入中</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Auth;
