/* eslint-disable no-undef */
import React, { useContext, useEffect } from 'react';
import useRWD from '../../hooks/useRWD';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { ToolOutlined, ApartmentOutlined, FundViewOutlined } from '@ant-design/icons';
import moment from 'moment';
import AuthContext from '../../contexts/authProvider';
import bg from '../../images/loginbg.jpg';
import bg_m from '../../images/loginbg_m.jpg';
import logo_w from '../../images/logo_w.svg';
import google_logo from '../../images/google_logo.svg';
import { API_BASE_URL } from '../../utils/config';

const Login = () => {
  const { user, AuthUser } = useContext(AuthContext);
  const device = useRWD();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const redirectPath = sessionStorage.getItem('redirectPath');
      sessionStorage.removeItem('redirectPath');
      navigate(redirectPath || '/filemanager');
    }

    const handleMessage = (event) => {
      if (event.origin === window.location.origin) {
        const data = event.data;
        if (data.token) {
          AuthUser(data);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [user, navigate, AuthUser]);

  const openNewWindowButton = () => {
    const url = `${API_BASE_URL}/google/login`;
    const width = 800;
    const height = 600;

    // 计算屏幕居中位置
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    // 设置窗口的名称和特性
    const name = '_blank';
    const specs = `width=${width},height=${height},left=${left},top=${top}`;

    // 使用 window.open 打开新窗口并指定长宽和位置
    window.open(url, name, specs);
  };

  return (
    <div
      className="app-login"
      style={
        device !== 'hisesPC' && device !== 'PC'
          ? { backgroundImage: `url(${bg_m})` }
          : { backgroundImage: `url(${bg})` }
      }
    >
      <div className="app-login-header">
        <div className="container">
          <div className="app-login-header-menu">
            <div className="logo">
              <img src={logo_w} alt="微電能源-智能資料平台" />
            </div>
            <div className="menu">
              <ol>
                <li>
                  <Button
                    type="link"
                    href="https://www.rfdme.com"
                    target="_blank"
                    icon={<ApartmentOutlined />}
                  >
                    微電官網
                  </Button>
                </li>
                <li>
                  <Button type="link" href="https://ems.rfdme.io" target="_blank" icon={<FundViewOutlined />}>
                    微電能源管理系統
                  </Button>
                </li>
                <li>
                  <Button type="link" href="https://om.rfdme.io" target="_blank" icon={<ToolOutlined />}>
                    微電維運系統
                  </Button>
                </li>
              </ol>
            </div>
            <div className="btn">
              <Button href="mailto:jack.yang@rfdme.com?subject=智能資料平台問題回報" ghost>
                問題回報
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="app-login-content">
        <div className="container">
          <div className="app-login-form">
            <h1>微電能源智能資料平台</h1>
            {process.env.REACT_APP_STAGE_NAME !== '正式區' && (
              <div className="stage-name">{process.env.REACT_APP_STAGE_NAME}</div>
            )}

            <Button
              // href={`${process.env.REACT_APP_APIGATEWAY_URL}/google/login`}
              onClick={openNewWindowButton}
              icon={<img alt="google" width="14" height="14" src={google_logo} />}
              size="large"
              ghost
              block
            >
              用Google SSO登入
            </Button>
          </div>
        </div>
      </div>
      <div className="app-login-footer">
        <div className="container">
          <p>©{moment().year()} Micro Electricity Co., Ltd. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
